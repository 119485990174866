<template>
  <div>
    <h1>Redigera presentation</h1>
    <div v-if="hasLoaded" class="slides__container">
      <div class="presentation__info">
        <input type="text" name="title" id="title" v-model="title" />
        <label for="voting">Visa omröstning</label>
        <input type="checkbox" name="voting" id="voting" v-model="voting" />
      </div>
      <div>
        <h2>Kapitel</h2>
        <draggable
          v-model="chapters"
          group="chapters"
          @start="drag = true"
          @end="drag = false"
          handle=".listitem__handle"
          class="list__container"
        >
          <div
            v-for="(chapter, index) in chapters"
            :key="chapter.id"
            @click="selectChapter(index)"
            class="listitem__slide"
            :class="index === activeChapter ? 'active' : ''"
          >
            <div class="listitem__handle"></div>
            <div class="listitem__content">
              <div class="listitem__slide-title">
                <span class="title">{{
                  chapter.chapterTitle
                    ? chapter.chapterTitle
                    : `Kapitel ${index}`
                }}</span>
                <!-- <span
                  v-if="chapters.length > 1"
                  class="delete"
                  @click="deleteChapter(index)"
                  >Radera</span
                > -->
                <div class="delete" @click="deleteChapter(index)">
                  <font-awesome-icon icon="trash-alt" class="icon" />
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <div class="btn" @click="newChapter">
          <font-awesome-icon icon="plus" class="icon" />Lägg till kapitel
        </div>
      </div>
      <div>
        <h2>Kapitelinfo</h2>
        <label for="chaptertitle">Titel</label>
        <input
          name="chaptertitle"
          type="text"
          v-model="chapters[activeChapter].chapterTitle"
          placeholder="Titel"
        />
        <h2>Slides</h2>
        <draggable
          v-model="chapters[activeChapter].slides"
          group="slides"
          @start="drag = true"
          @end="drag = false"
          handle=".listitem__handle"
          class="list__container"
        >
          <div
            v-for="(slide, index) in chapters[activeChapter].slides"
            :key="slide.id"
            @click="selectMainSlide(index)"
            class="listitem__slide"
            :class="index === selectedSlide ? 'active' : ''"
          >
            <div class="listitem__handle"></div>
            <div class="listitem__content">
              <div class="listitem__slide-title">
                <span class="title">{{
                  slide.title ? slide.title : "Tom slide"
                }}</span>
                <!-- <span class="delete" @click="deleteSlide(index)">Radera</span> -->
                <div class="delete" @click="deleteSlide(index)">
                  <font-awesome-icon icon="trash-alt" class="icon" />
                </div>
              </div>
              <div
                class="listitem__subslide"
                v-for="(subslide, subIndex) in chapters[activeChapter].slides[
                  index
                ].subslides"
                :key="subslide.id"
                @click="selectedSubSlide = subIndex"
              >
                - {{ subslide.title }}
              </div>
            </div>
          </div>
        </draggable>
        <div class="btn" @click="newSlide">
          <font-awesome-icon icon="plus" class="icon" />Lägg till slide
        </div>
        <h2>Underslides</h2>
        <div
          v-if="chapters[activeChapter].slides[selectedSlide].subslides.length"
          class="subslidelist__container"
        >
          <draggable
            v-model="chapters[activeChapter].slides[selectedSlide].subslides"
            group="subslides"
            @start="drag = true"
            @end="drag = false"
            handle=".handle"
          >
            <div
              v-for="(subslide, index) in chapters[activeChapter].slides[
                selectedSlide
              ].subslides"
              :key="subslide.id"
              @click="selectedSubSlide = index"
              class="listitem__slide"
              :class="index === selectedSubSlide ? 'active' : ''"
            >
              <img src="@/assets/handle.svg" class="handle" />
              <span class="subslide__title">{{ subslide.title }}</span>
              <div class="delete" @click="deleteSubSlide(index)">
                <font-awesome-icon icon="trash-alt" class="icon" />
              </div>
              <!-- <span class="delete" @click="deleteSubSlide(index)">Radera</span> -->
            </div>
          </draggable>
        </div>
        <div class="btn" @click="newSubSlide">
          <font-awesome-icon icon="plus" class="icon" />Lägg till underslide
        </div>
      </div>
      <div>
        <h2>Slide</h2>
        <label>Bild</label>
        <plus-placer
          v-bind:yCoord="subslideCoordinates.y"
          v-bind:xCoord="subslideCoordinates.x"
          @coordinates-set="handleCoordinates"
          v-bind:imageUrl="
            chapters[activeChapter].slides[selectedSlide].imageUrl
          "
        />
        <label for="title">Titel</label>
        <input
          name="title"
          type="text"
          v-model="chapters[activeChapter].slides[selectedSlide].title"
          placeholder="Titel"
        />
        <label for="description">Beskrivning</label>
        <input
          name="description"
          type="text"
          v-model="chapters[activeChapter].slides[selectedSlide].description"
          placeholder="Beskrivning"
        />
        <div class="dropbox">
          <input
            type="file"
            name="slideimage"
            ref="slideUpload"
            id="slideimage"
            accept="image/png, image/jpeg"
            placeholder="Ladda upp"
            class="input__file"
            @change="uploadSlide('slideUpload')"
          />
          <p>
            Släpp bild här eller<br />
            klicka för att välja
          </p>
        </div>
      </div>
      <div>
        <h2>Subslide</h2>
        <div v-if="hasSubSlides" class="w100">
          <label>Bild</label>
          <SubslideImage
            v-bind:imageUrl="
              chapters[activeChapter].slides[selectedSlide].subslides[
                selectedSubSlide
              ].imageUrl
            "
          />
          <label for="subslidetitle">Titel</label>
          <input
            name="subslidetitle"
            type="text"
            v-model="
              chapters[activeChapter].slides[selectedSlide].subslides[
                selectedSubSlide
              ].title
            "
            placeholder="Titel"
          />
          <div class="dropbox">
            <input
              type="file"
              name="slideimage"
              ref="subSlideUpload"
              id="subSlideimage"
              accept="image/png, image/jpeg"
              placeholder="Ladda upp"
              class="input__file"
              @change="uploadSlide('subSlideUpload')"
            />
            <p>
              Släpp bild här eller<br />
              klicka för att välja
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="buttongroup">
      <div class="btn btn__large" @click="updatePresentation">
        <font-awesome-icon icon="save" class="icon" />Spara presentationen
      </div>
    </div>
  </div>
</template>

<script>
import * as fb from "../firebase";
import { mapGetters, mapActions } from "vuex";
import PlusPlacer from "../components/PlusPlacer.vue";
import SubslideImage from "../components/SubslideImage.vue";
import draggable from "vuedraggable";

export default {
  components: { PlusPlacer, draggable, SubslideImage },
  data: function () {
    return {
      title: "",
      chapters: [],
      presenter: [],
      activeChapter: 0,
      selectedSlide: 0,
      selectedSubSlide: 0,
      hasLoaded: false,
      voting: false,
    };
  },
  methods: {
    ...mapActions(["GETPRESENTATIONACTION"]),
    makeid(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    newChapter() {
      this.activeChapter = this.chapters.length;
      this.selectedSlide = 0;
      this.selectedSubSlide = 0;
      this.chapters.push({
        chapterTitle: "Nytt kapitel",
        slides: [
          {
            title: "",
            description: "",
            imageUrl: "",
            order: "",
            subslides: [],
          },
        ],
      });
    },
    deleteChapter(chapter) {
      if (confirm("Är du säker på att du vill ta bort det här kapitlet?")) {
        this.chapters.splice(chapter, 1);
        if (this.activeChapter == chapter && chapter > 0) {
          this.activeChapter = chapter - 1;
        }
      }
    },
    newSlide() {
      this.selectedSlide = this.chapters[this.activeChapter].slides.length;
      this.chapters[this.activeChapter].slides.push({
        title: "",
        description: "",
        imageUrl: "",
        order: "",
        subslides: [],
      });
    },
    deleteSlide(slide) {
      if (confirm("Är du säker på att du vill ta bort den här sliden?")) {
        this.chapters[this.activeChapter].slides.splice(slide, 1);
        if (this.selectedSlide == slide && slide > 0) {
          this.selectedSlide = slide - 1;
        }
      }
    },
    newSubSlide() {
      this.selectedSubSlide =
        this.chapters[this.activeChapter].slides[
          this.selectedSlide
        ].subslides.length;
      this.chapters[this.activeChapter].slides[
        this.selectedSlide
      ].subslides.push({
        imageUrl: "",
        x: 50,
        y: 50,
        open: false,
        title: "",
      });
    },
    deleteSubSlide(slide) {
      if (confirm("Är du säker på att du vill ta bort den här undersliden?")) {
        this.chapters[this.activeChapter].slides[
          this.selectedSlide
        ].subslides.splice(slide, 1);
        if (this.selectedSubSlide == slide && slide > 0) {
          this.selectedSubSlide = slide - 1;
        }
      }
    },
    selectChapter(index) {
      this.activeChapter = index;
      this.selectedSlide = 0;
      this.selectedSubSlide = 0;
    },
    selectMainSlide(index) {
      this.selectedSlide = index;
      this.selectedSubSlide = 0;
    },
    updatePresentation() {
      fb.db
        .collection("presentations")
        .doc(this.$route.params.id)
        .update({
          user: this.$store.state.uid,
          title: this.title,
          chapters: this.chapters,
          voting: this.voting,
        })
        .then(() => {
          console.log("Document updated");
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    handleCoordinates(args) {
      this.chapters[this.activeChapter].slides[this.selectedSlide].subslides[
        this.selectedSubSlide
      ].x = args.X;
      this.chapters[this.activeChapter].slides[this.selectedSlide].subslides[
        this.selectedSubSlide
      ].y = args.Y;
    },
    uploadSlide(ref) {
      var storage = fb.storage;
      var storageRef = storage.ref();
      var file = this.$refs[ref].files[0];
      var fileType = file.type;
      var metadata = {
        contentType: fileType,
      };
      var uploadTask = storageRef
        .child(this.$route.params.id + "/" + this.makeid(6) + file.name)
        .put(file, metadata);
      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          // Handle successful uploads on complete
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            //console.log("File available at", downloadURL);
            if (ref == "subSlideUpload") {
              this.chapters[this.activeChapter].slides[
                this.selectedSlide
              ].subslides[this.selectedSubSlide].imageUrl = downloadURL;
            } else {
              this.chapters[this.activeChapter].slides[
                this.selectedSlide
              ].imageUrl = downloadURL;
            }
          });
        }
      );
    },
  },
  computed: {
    ...mapGetters(["getChapters"]),
    subslideCoordinates() {
      if (
        typeof this.chapters[this.activeChapter].slides[this.selectedSlide]
          .subslides == "undefined"
      ) {
        return { x: null, y: null };
      } else if (
        this.chapters[this.activeChapter].slides[this.selectedSlide].subslides
          .length == 0
      ) {
        return { x: null, y: null };
      } else {
        return {
          x: this.chapters[this.activeChapter].slides[this.selectedSlide]
            .subslides[this.selectedSubSlide].x,
          y: this.chapters[this.activeChapter].slides[this.selectedSlide]
            .subslides[this.selectedSubSlide].y,
        };
      }
    },
    hasSubSlides() {
      if (
        typeof this.chapters[this.activeChapter].slides[this.selectedSlide]
          .subslides == "undefined"
      ) {
        return false;
      } else if (
        this.chapters[this.activeChapter].slides[this.selectedSlide].subslides
          .length > 0
      ) {
        return true;
      }
      return false;
    },
  },
  async created() {
    let presRef = fb.db.collection("presentations").doc(this.$route.params.id);
    let _presentation = await presRef.get();
    for (const doc of _presentation.data().chapters) {
      this.chapters.push(doc);
    }
    this.title = _presentation.data().title;
    this.voting = _presentation.data().voting;
    if (_presentation.data().title) {
      this.hasLoaded = true;
    }
  },
  beforeMount() {
    this.GETPRESENTATIONACTION(this.$route.params.id);
  },
};
</script>

<style lang="scss">
html {
  font-size: 14px;
}
.slides__container {
  text-align: left;
  gap: 1rem;
  display: grid;
  grid-template-columns: 2fr 2fr 3fr 3fr;
  grid-template-rows: auto 1fr;
  height: 100%;
  margin: 1rem;
  .presentation__info {
    grid-column: 1/5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    input {
      width: auto;
      flex: 0 1 auto;
      font-size: 1rem;
      margin: 0 1rem 0 0;
    }
    input[type="text"] {
      width: 50%;
    }
    label {
      font-size: 0.9rem;
      text-transform: none;
      letter-spacing: normal;
      margin-right: 0.5rem;
    }
  }
  h2 {
    font-size: 1rem;
    margin: 0;
    margin: -0.5rem;
    margin-bottom: 1rem;
    background-color: #ddd;
    color: #555;
    width: 100%;
    padding: 0.5rem;
    text-transform: uppercase;
  }
  h2:not(:first-child) {
    margin-top: 1rem;
  }
  input {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    padding: 0.3rem;
  }
  label {
    font-size: 10px;
    margin-bottom: 0.2rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #888;
    display: block;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem;
    background-color: #f5f5f5;
  }
  .btn {
    margin-left: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.list__container {
  width: 100%;
}
.listitem__handle {
  flex: 0 0 1rem;
  background-color: #555;
  cursor: move;
}
.listitem__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
.listitem__slide {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: left;
  user-select: none;
  border: 1px solid #555;
  border-radius: 3px;
  margin-bottom: 0.5rem;
  &:hover {
    cursor: pointer;
  }
  .delete:hover {
    color: red;
  }
  &.active {
    background-color: #ddd;
  }
}
.listitem__slide-title {
  font-weight: 700;
  padding: 0.3rem;
  transition: background-color 150ms ease-in-out;
  display: flex;
  flex-direction: row;
  span.title {
    flex-grow: 1;
  }
}
.delete {
  color: #aaa;
  font-weight: 300;
  padding-left: 0.5rem;
  transition: color ease-in-out 150ms;
}
.listitem__subslide {
  display: block;
  padding: 0.3rem;
  transition: background-color 150ms ease-in-out;
}
.listitem__slide-title:hover,
.listitem__subslide:hover {
  background-color: #bbb;
}
.subslidelist__container {
  width: 100%;
  .listitem__slide {
    padding: 0.3rem;
  }
}
.handle {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 1rem;
  cursor: move;
}
div.dropbox {
  outline: 1px dashed #777; /* the dash box */
  outline-offset: -5px;
  background: #ddd;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 5rem;
  cursor: pointer;
  p {
    font-size: 1rem;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  &:hover {
    background: #ccc; /* when mouse over to the drop zone, change color */
  }
  .input__file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: none;
  }
}
.buttongroup {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1rem;
}
.cancel-btn {
  padding: 0.5em 1em;
  background-color: #eccfc9;
  color: #c5391a;
  border: 2px solid #ea3f1b;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>