<template>
<div class="container" :style="{ backgroundImage: 'url(' + imageUrl + ')' }" @click="printCoords" ref="clickContainer">
  <div class="shaper">&nbsp;</div>
  <svg v-if="xCoord" viewBox="0 0 24 24" :style="{ top: yCoord + '%', left: xCoord + '%' }"><polygon points="24 10 14 10 14 0 10 0 10 10 0 10 0 14 10 14 10 24 14 24 14 14 24 14 24 10" style="fill:#ffc517"/></svg>
  </div>  
</template>

<script>
export default {
  props: ['xCoord', 'yCoord', 'imageUrl'],
  methods: {
    printCoords: function(event) {
      let X = Math.round((event.pageX - this.$refs.clickContainer.offsetLeft) / this.$refs.clickContainer.offsetWidth * 100)
      let Y = Math.round((event.pageY - this.$refs.clickContainer.offsetTop) / this.$refs.clickContainer.offsetHeight * 100)
      this.$emit('coordinates-set', {X, Y})
    }
  }

}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center center;
  .shaper {
    width: 100%;
    padding-top: 56.25%;
  }
  svg {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    transform: translateX(-12px) translateY(-12px);   
  }
}

</style>