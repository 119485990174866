<template>
	<div
		class="subslideimage__container"
		:style="{ backgroundImage: 'url(' + imageUrl + ')' }"
	>
		<div class="subslide__shaper">&nbsp;</div>
	</div>
</template>

<script>
export default {
  props: ['imageUrl']
}
</script>

<style lang="scss" scoped>
.subslideimage__container {
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center center;
  .subslide__shaper {
    width: 100%;
    padding-top: 56.25%;
  }
}
</style>